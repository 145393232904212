let showContainer = document.querySelector('#show-container')
let showButton = document.querySelector('#show-button')


showButton.addEventListener('click', () => {
    showContainer.classList.toggle('opening') 
    showContainer.classList.toggle('gradient-bottom')
    if (showButton.textContent === 'Show more') {
        showButton.textContent = 'Hide'
    } else {
        showButton.textContent = 'Show more'
    }
})